@import "../mixins/breakpoints";

.accordion {
    @apply relative flex flex-col;
    .accordion-label {
        @apply cursor-pointer font-bold text-dark dark:text-light capitalize relative w-full flex items-center gap-2;

        &::after {
            font-family: FontAwesome;
            content: "\f0d7";
            color: inherit;
            @apply absolute right-4;
        }
    }
    .accordion-content {
        height: 0;
        @apply flex flex-col gap-2 overflow-y-hidden text-sm lowercase;

        li {
            @apply p-0 text-sm w-full;
            a {
                @apply flex p-1 w-full;
            }
        }

        li:hover {
            @apply font-semibold;

            a {
                @apply bg-slate-600;
            }
        }
    }

    &.active .accordion-label {
        &::after {
            @apply text-primary transform rotate-180 transition ease-in;
        }
    }

    &.active .accordion-content {
        @apply h-fit py-2 text-subdark dark:text-sublight transition-all duration-1000 delay-100 flex flex-col;
    }
}
