@import "../mixins/breakpoints";

#navigation {
    @apply text-dark dark:text-light bg-[#00959b40];
    .logo {
        span {
            @apply text-dark dark:text-light;
        }
    }
    .hamburger {
        span {
            @apply bg-dark dark:bg-light;
        }
    }
    .hamburger.active {
        span:nth-child(1) {
            transform: rotate(45deg);
        }
        span:nth-child(2) {
            transform: scale(0);
        }
        span:nth-child(3) {
            transform: rotate(-45deg);
        }
    }

    .nav-dropdown {
        @apply flex items-center transition-all duration-[.8s] overflow-y-hidden lg:overflow-y-visible;

        @include responsive("sm") {
            max-height: 0;
        }
        @include responsive("lg") {
            max-height: fit-content;
        }
        a.active {
            @apply text-secondary font-semibold;
        }
    }

    &.active .nav-dropdown {
        @include responsive("sm") {
            max-height: fit-content;
        }

        .nav-dropdown-item {
            @apply w-full p-2 text-dark lg:text-primary dark:text-sublight;

            a {
                @apply flex w-full;
            }
        }
        .nav-dropdown-item:hover {
            @apply bg-secondary;
        }
    }

    &.active #theme-toggler {
        @apply w-full p-2 text-subdark dark:text-sublight;
    }
    &.sticky-navigation {
        width: 100%;
        position: fixed;
        z-index: 98;
        left: 0;
        right: 0;
        top: -1%;
        @apply bg-light dark:bg-subdark backdrop-blur-md;

        .logo {
            @apply text-secondary;
            span {
                @apply text-dark;
            }
        }
        &.active .nav-dropdown {
            .nav-dropdown-item {
                @apply text-dark dark:text-light;
            }
        }
        &.active #theme-toggler {
            @apply text-light dark:text-dark;
        }
    }
}
