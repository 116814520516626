@import "../mixins/breakpoints";

.dropdown {
    @apply relative flex flex-col;
    .dropdown-label {
        @apply cursor-pointer flex items-center gap-2;

        &::after {
            font-family: FontAwesome;
            content: "\f0d7";
            color: inherit;
        }
    }

    .dropdown-content {
        max-height: 0;
        @apply flex flex-col gap-2 overflow-y-hidden text-sm lowercase;

        li {
            @apply p-0 text-sm w-full;
            a {
                @apply flex p-1 w-full;
            }
        }

        li:hover {
            @apply font-semibold;

            a {
                @apply bg-transparent cursor-pointer;
            }
        }
    }

    &.active .dropdown-label {
        &::after {
            @apply transform rotate-180 transition ease-in;
        }
    }

    &.active .dropdown-content {
        max-height: fit-content;
        @apply rounded-md shadow-md p-2 transition duration-500 delay-100 flex flex-col;

        @include responsive("lg") {
            @apply absolute inset-x-0 top-[100%] bg-sublight dark:bg-subdark w-48;
        }
    }
}
