.alert {
    @apply transition-all rounded-md p-2 w-fit shadow-md dark:shadow-light;

    &-error,
    &-danger {
        @apply bg-rose-500 text-light;
    }
    &-success {
        @apply bg-green-400 text-light;
    }
    &-info {
        @apply bg-sky-300 text-dark;
    }
    &-warning {
        @apply bg-yellow-400 text-dark;
    }
    &-primary {
        @apply bg-primary text-dark;
    }
    &-secondary {
        @apply bg-secondary text-light;
    }
}
