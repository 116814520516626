article {
    @apply flex flex-col gap-2 px-2 text-justify first-letter:text-2xl first-letter:uppercase font-inter;

    h1 {
        @apply text-2xl font-bold;
    }
    h2 {
        @apply text-xl font-semibold;
    }

    h3,
    h4 {
        @apply text-lg font-semibold;
    }

    h5 {
        @apply text-lg font-semibold;
    }
    p {
        @apply text-base;
    }
    ol {
        @apply mx-8 p-2 text-base;
        li {
            @apply list-decimal;
        }
    }
    ul {
        @apply mx-8 p-2 text-base;
        li {
            @apply list-disc;
        }
    }

    figure {
        @apply mx-auto max-w-lg flex flex-col items-center;

        img {
            @apply h-auto max-w-full rounded-lg object-cover;
        }
        figcaption {
            @apply mt-2 text-sm text-center text-subdark dark:text-sublight;
        }
    }
}
