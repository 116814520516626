@import "./base";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css";
@import "./includes/wow.min.css";
@import "./includes/gfonts.css";
@import "./components/nav";
@import "./components/article";
@import "./partials/dropdown";
@import "./partials/accordion";
@import "./partials/spinner";
@import "./partials/alert";
@import "./partials/ads";
@import "./partials/ads";

svg {
    @apply bg-transparent;
}
section {
    @apply h-fit;
}
::-webkit-scrollbar {
    width: 0;
}
.overflow-y-hidden,
.overflow-y-hidden,
.overflow-scroll {
    @apply cursor-all-scroll;
}

.dropdown .fa {
    width: 0.8rem;
}

@layer utilities {
    a {
        @apply transition-all duration-700;
    }

    a:hover {
        @apply underline border-dark;
    }
}
