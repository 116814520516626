.ads {
    @apply hidden;
}

.accept-ads {
    .ads {
        @apply flex bg-gray-300 relative before:absolute before:right-2 before:top-2 before:w-6 before:h-6 before:rounded-full after:absolute after:text-sm after:w-full after:h-full after:flex after:items-center after:justify-center;

        &::after {
            content: "advertisment";
        }
        &::before {
            @apply bg-light shadow-lg flex items-center justify-center p-2;
            content: "\f00d";
            font-family: "FontAwesome";

            &:hover {
                @apply shadow-2xl scale-75;
            }
        }

        img {
            @apply w-full h-full;
        }

        &.ads-small-banner {
            @apply sm:w-[300px] sm:h-[50px] md:w-[468px] md:h-[60px];
        }

        &.ads-large-banner {
            @apply sm:w-[320px] sm:h-[100px] md:w-[930px] md:h-[180px];
        }

        &.ads-small-square {
            @apply w-[200px] h-[200px];
        }

        &.ads-large-square {
            @apply w-[250px] h-[250px];
        }
    }
}
